import React, { useState, useMemo } from "react";
import { FaUsers, FaSearch, FaFilter } from "react-icons/fa";
import icon from "./assets/icon-1.svg";
import icon2 from "./assets/icon-2.svg";
import icon3 from "./assets/icon-3.svg";
import icon4 from "./assets/icon-4.svg";

const Dashboard = () => {
  const [showMoreUsers, setShowMoreUsers] = useState(false);
  const [filter, setFilter] = useState("All"); // Default filter
  const [search, setSearch] = useState(""); // Search input state
  const [showFilterOptions, setShowFilterOptions] = useState(false); // Manage filter visibility

  // Dummy table data
  const tableData = [
    {
      name: "ABC Mutual Fund",
      type: "Equity",
      category: "Large Cap",
      clients: 5,
    },
    {
      name: "XYZ Mutual Fund",
      type: "Debt",
      category: "Short Term",
      clients: 3,
    },
    {
      name: "PQR Mutual Fund",
      type: "Hybrid",
      category: "Balanced",
      clients: 4,
    },
    { name: "New Fund", type: "Equity", category: "Mid Cap", clients: 6 },
  ];

  const summaryData = [
    { icon: icon, label: "Total Users", value: 1200 },
    { icon: icon2, label: "New Users", value: 75 },
    { icon: icon3, label: "Total Mutual Funds", value: 45 },
    { icon: icon4, label: "Total Transactions", value: 350 },
  ];

  // Dynamically extract unique fund types
  const fundTypes = useMemo(
    () => ["All", ...new Set(tableData.map((item) => item.type))],
    [tableData]
  );

  // Filter and search logic
  const filteredData = useMemo(() => {
    return tableData.filter(
      (item) =>
        (filter === "All" || item.type === filter) &&
        item.name.toLowerCase().includes(search.toLowerCase())
    );
  }, [filter, search, tableData]);

  return (
    <div className="p-8">
        <h1 className="text-[#202224] text-[1.45vw] font-semibold">Dashboard</h1>
      {/* Summary Boxes */}
      <div className="flex justify-between my-8 space-x-4">
        {summaryData.map((item, index) => (
          <div
            key={index}
            className="flex items-center justify-between bg-white shadow-md p-6 rounded-lg w-1/4"
          >
            <div className="text-left">
              <h2 className="text-[1.05vw] font-semibold">{item.label}</h2>
              <p className="text-xl mt-4">{item.value}</p>
            </div>
            <div className="text-4xl text-gray-700">
              <img src={item.icon} alt="icon" />
            </div>
          </div>
        ))}
      </div>

      <div className="flex justify-between items-center">
        <h1 className="text-[#202224] text-[1.45vw] font-semibold">Mutual Funds</h1>
        {/* Filter and Search Section */}
        <div className="flex items-center justify-end mb-6 space-x-2">
          {/* Filter Button */}
          <button
            className="flex items-center space-x-2 bg-white text-gray-600 border rounded-md px-4 py-2 shadow-md"
            onClick={() => setShowFilterOptions((prev) => !prev)}
          >
            <FaFilter />
            <span>Filter</span>
          </button>

          {/* Search Input */}
          <div className="relative ">
            <input
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search mutual funds..."
              className="w-full pl-10 pr-4 py-2 border rounded-md shadow-md focus:outline-none"
            />
            <FaSearch className="absolute top-3 left-3 text-gray-500" />
          </div>
        </div>
      </div>

      {/* Filter Dropdown (conditionally rendered) */}
      {showFilterOptions && (
        <div className="bg-white border shadow-md rounded-md p-4 mb-4">
          <h3 className="text-lg font-semibold mb-2">Select Type</h3>
          {fundTypes.map((type, index) => (
            <button
              key={index}
              className={`block px-4 py-2 w-full text-left hover:bg-gray-100 ${
                filter === type ? "bg-gray-200 font-semibold" : ""
              }`}
              onClick={() => {
                setFilter(type);
                setShowFilterOptions(false); // Close the dropdown after selection
              }}
            >
              {type}
            </button>
          ))}
        </div>
      )}

      {/* Table */}
      <table className="w-full bg-white shadow-md rounded-lg overflow-hidden mt-4">
        <thead>
          <tr className="bg-gray-100 text-left">
            <th className="p-4">Mutual Fund Name</th>
            <th className="p-4">Type</th>
            <th className="p-4">Category</th>
            <th className="p-4">Clients</th>
            <th className="p-4">Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.length > 0 ? (
            filteredData.map((item, index) => (
              <tr key={index} className="border-b">
                <td className="p-4">{item.name}</td>
                <td className="p-4">{item.type}</td>
                <td className="p-4">{item.category}</td>
                <td className="p-4">
                  <div className="relative flex items-center space-x-2">
                    <div className="relative">
                      {[...Array(3)].map((_, i) => (
                        <FaUsers
                          key={i}
                          className={`text-gray-500 absolute -left-[${
                            i * 8
                          }px]`}
                        />
                      ))}
                    </div>
                    <button
                      className="ml-8 text-blue-500 hover:underline"
                      onClick={() => setShowMoreUsers(!showMoreUsers)}
                    >
                      +{item.clients - 3}
                    </button>
                    {showMoreUsers && (
                      <div className="ml-4 flex space-x-2">
                        {[...Array(item.clients - 3)].map((_, i) => (
                          <FaUsers key={i} className="text-gray-500" />
                        ))}
                      </div>
                    )}
                  </div>
                </td>
                <td className="p-4">
                  <button className="bg-white font-medium text-[#FF9800] px-4 py-2 rounded-md">
                    Review
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td className="p-4 text-center" colSpan="5">
                No matching records found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Dashboard;

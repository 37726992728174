import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Sidebar from "./components/sidebar/Sidebar";
import Navbar from "./components/navbar/Navbar";
import Dashboard from "./components/dashboard/Dashboard";
import RiskAnalyzer from "./components/riskAnalyzer/RiskAnalyzer";
import CreateQuestion from "./components/riskAnalyzer/CreateQuestion";
import QuestionDetail from "./components/riskAnalyzer/QuestionDetail";
import RiskProfiles from "./components/riskAnalyzer/RiskProfiles";
import AddCategory from "./components/riskAnalyzer/AddCategory";
import CANManagement from "./components/canManagement/CANManagement";

function App() {
  return (
    <Router>
      <div className="flex">
        <Sidebar />
        <div className="w-[80%]">
          <Navbar />
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/risk-analyzer" element={<RiskAnalyzer />} />
            <Route path="/create-question" element={<CreateQuestion />} />
            <Route path="/question-detail/:id" element={<QuestionDetail />} />
            <Route path="/risk-profiles" element={<RiskProfiles />} />
            <Route path="/add-category" element={<AddCategory />} />
            <Route path="/edit-category/:id" element={<AddCategory />} />
            <Route path="/mutual-funds" element={<div>Mutual Funds</div>} />
            <Route path="/can" element={<CANManagement />} />
            <Route path="/epayeezz" element={<div>EpayEezz</div>} />
            <Route path="/orders" element={<div>Orders</div>} />
            <Route path="/notifications" element={<div>Notifications</div>} />
            <Route path="/help-support" element={<div>Help & Support</div>} />
            <Route
              path="/user-management"
              element={<div>User Management</div>}
            />
            <Route
              path="/role-management"
              element={<div>Role Management</div>}
            />
            <Route path="/settings" element={<div>Settings</div>} />
            <Route path="/logout" element={<div>Logout</div>} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
